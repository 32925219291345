import React from "react";
import AppRouter from "./AppRouter";
import CssBaseline from "@material-ui/core/CssBaseline";

function App() {
    return (
        <div>
            <CssBaseline />
            <AppRouter />
        </div>
    );
}

export default App;
