import React from "react";
import { AppBar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles(() => ({
    header: {
        backgroundColor: "#002b55",
        padding: "10px",
    },
    headerImage: {
        // height: "100px",
        // width: "210px",
        width: "15%",
        display: "block",
        margin: "0 auto",
    },

    noticeBoard: {
        margin: "50px auto 80px",
        width: "60%",
    },
    intro: {
        backgroundColor: "#123349",
        color: "white",
        fontSize: 20,
        textAlign: "center",
        padding: 30,
        borderRadius: 0,
    },
    section: {
        margin: "auto",
        width: "70%",
    },
    div: {
        maxidth: 700,
        margin: "auto",
        paddingBottom: 10,
    },
    text1: {
        textAlign: "center",
    },
    btn: {
        display: "block",
        padding: "20px 20px 20px 20px",
        backgroundColor: "#F34F03",
        width: "200px",
        color: "#fff",
        textDecoration: "none",
        textTransform: "uppercase",
        textAlign: "center",
        margin: "auto",
    },
}));
export default function Header() {
    const classes = useStyles();
    return (
        <div>
            <div
                style={{
                    background:
                        "url('https://cdn2.hubspot.net/hub/302700/file-350777633-png/images/header-wapper-bg.png') repeat-x left top #152f44",
                    paddingTop: "30px",
                    boxShadow: "0 0 3px 0 rgb(0 0 0 / 80%)",
                }}
            ></div>
            <AppBar className={classes.header} position="static">
                <img className={classes.headerImage} src="/images/Beretta_Logo.png" alt="Beretta" align="center" />
            </AppBar>
        </div>
    );
}
