import React from "react";

import { Paper, makeStyles, Typography } from "@material-ui/core";
import Header from "./Header";
import Footer from "./Footer";

const useStyles = makeStyles(() => ({
    noticeBoard: {
        margin: "50px auto 80px",
        width: "900px",
    },
    download: {
        color: "#123349",
        margin: "25px auto 0",
        textAlign: "center",
    },
    intro: {
        backgroundColor: "#123349",
        color: "white",
        fontSize: 20,
        textAlign: "center",
        padding: 30,
        borderRadius: 0,
    },
    section: {
        margin: "10px auto",
        width: "70%",
    },
    div: {
        maxidth: 700,
        margin: "auto",
        paddingBottom: 10,
    },
    text1: {
        textAlign: "center",
    },
    btn: {
        display: "block",
        padding: "20px 20px 20px 20px",
        backgroundColor: "#F34F03",
        width: "200px",
        color: "#fff",
        textDecoration: "none",
        textTransform: "uppercase",
        textAlign: "center",
        margin: "30px auto",
    },
}));

export default function RecallNotice() {
    const classes = useStyles();
    return (
        <div
            style={{
                background: "url('https://cdn2.hubspot.net/hub/302700/file-352328646-jpg/images/site_bg.jpg') repeat",
            }}
        >
            <Header />
            <div className={classes.download}>
                <a href="/Beretta - Neos Recall.pdf" download>
                    <Typography variant="subtitle1" display="inline">
                        Download PDF
                    </Typography>
                </a>
            </div>
            <Paper className={classes.noticeBoard}>
                <div className={classes.intro}>
                    <strong>WARNING: SAFETY RECALL NOTICE</strong>
                </div>

                <section className={classes.section}>
                    <div className={classes.div} style={{ textAlign: "center" }}>
                        <div style={{ margin: "80px" }}></div>
                        <strong style={{ color: "#ff0201", fontSize: "20px" }}>
                            RISK OF FIREARM ACCIDENTAL DISCHARGE INVOLVING RECENTLY MANUFACTURED BERETTA MODEL U22 NEOS
                            PISTOLS
                        </strong>
                        <div style={{ fontWeight: "bold", padding: "15px" }}>
                            THIS RECALL NOTICE IS FOR OWNERS OF BERETTA MODEL U22 NEOS PISTOLS PURCHASED AFTER JANUARY
                            1, 2015
                        </div>
                        <div className={classes.div} />
                    </div>
                    <div className={classes.div} style={{ lineHeight: 1.75 }}>
                        <span style={{ color: "#ff0201", textTransform: "uppercase" }}>
                            WARNING: BERETTA MODEL U22 NEOS PISTOLS PURCHASED AFTER JANUARY 1, 2015 MAY HAVE A CONDITION
                            THAT ALLOWS THE PISTOL TO FIRE EVEN IF THE SAFETY LEVER IS ENGAGED. IN MORE RARE
                            CIRCUMSTANCES, ENGAGEMENT OR DISENGAGEMENT OF THE SAFETY LEVER ITSELF CAN CAUSE ACCIDENTAL
                            DISCHARGE. IF THE PISTOL IS POINTED IN AN UNSAFE DIRECTION WHEN THIS CONDITION OCCURS,
                            DISCHARGE OF THE PISTOL CAN CREATE A RISK OF INJURY OR DEATH.
                        </span>
                    </div>
                    <div className={classes.div} />
                    <div className={classes.div}>
                        <p>
                            THIS CONDITION ONLY DEVELOPS AFTER EXTENSIVE USE OF THE PISTOL (AT LEAST 500 ROUNDS FIRED
                            AND MAYBE AFTER AS MANY AS 2000 ROUNDS). FOR THIS REASON, EVEN IF THE SAFETY LEVER ON YOUR
                            NEOS PISTOL WORKS NOW, IT MUST STILL BE RECALLED AND REPAIRED.
                        </p>
                    </div>
                    <div className={classes.div} />
                    <div className={classes.div} style={{ lineHeight: 1.75 }}>
                        If you purchased a Beretta u22 Neos pistol after January 1, 2015, immediately ensure the pistol
                        is unloaded, always keeping the pistol pointed in a safe direction, then click the “How to
                        Submit a Repair” button at the bottom of the page for instructions concerning how to return your
                        U22 Neos pistol to Beretta Canada for repair and return to you.
                        <br />
                        <br />
                        A simple repair procedure eliminates the cause of this recall notice and will allow return and
                        continued future use of your Beretta U22 Neos pistol. Beretta Canada will pay all costs of
                        shipping and repair.
                        <br />
                        <br />
                        NOTE: If you bought a used U22 NEOS pistol in recent years and do not know whether it was
                        originally purchased after January 1, 2015, you can find out whether your pistol is within the
                        serial number range involved in the recall on the next page. If it is not, if your pistol has
                        already been repaired or if your pistol was made after the production period involved in the
                        recall, it is not subject to this recall and may continue to be used as is.
                    </div>
                    <div className={classes.div} style={{ textAlign: "center" }}>
                        <p>
                            IF YOU HAVE QUESTIONS PLEASE CONTACT US AT:
                            <br />
                            service@stoegercanada.ca | 1-800-263-1945 | 905-436-9077
                        </p>
                        <a href="/recall" className={classes.btn}>
                            How to submit for a repair
                        </a>
                        {/* <div style={{ color: "#ff0201", margin: "30px 0", fontWeight: "bold" }}>
                            Further infomation on how to submit for a repair will be provided shortly. <br />
                            Please check back shortly.
                        </div> */}
                        <div className={classes.div} />

                        <div style={{ margin: "20px 10px" }}></div>
                    </div>
                </section>
            </Paper>
            <Footer />
        </div>
    );
}
