import React, { useEffect, useState } from "react";
import { Button, InputLabel, makeStyles, Paper, TextField } from "@material-ui/core";
import Header from "./Header";
import Footer from "./Footer";

const useStyles = makeStyles(() => ({
    noticeBoard: {
        margin: "50px auto 80px",
        width: "900px",
        backgroundColor: "#f0f0f0",
    },
    intro: {
        backgroundColor: "#123349",
        color: "white",
        fontSize: 14,
        textAlign: "center",
        padding: 16,
        borderRadius: 0,
    },
    section: {
        margin: "auto",
        // width: "70%",
        padding: "0 14%",
    },
    section2: {
        margin: "auto",
        // width: "70%",
        padding: "0 14%",
        backgroundColor: "white",
    },
    div: {
        maxidth: 700,
        margin: "auto",
        paddingBottom: 10,
    },
    li: {
        fontWeight: "bold",
        textTransform: "uppercase",
        color: "#4a4a4a",
        lineHeight: "19.6px",
        marginBottom: "20px",
    },
    liP: {
        fontSize: "12px",
        marginBottom: 5,
    },
    btn: {
        margin: "10px 0",
    },
    case: {
        margin: "20px auto 10px",
        width: "50%",
        // padding: "10px",
        border: "2px solid #F34F03",
        fontSize: "18px",
        padding: "20px",
    },
}));

export default function Recall(props) {
    const classes = useStyles();

    const [serialData, setSerialData] = useState({
        accessToken: "",
        serial: "",
    });

    const [showCaseStatus, setShowCaseStatus] = useState(false);
    const [caseStatus, setCaseStatus] = useState("");

    const handleSerialData = (e) => {
        setSerialData({
            ...serialData,
            serial: e.target.value,
        });
    };
    useEffect(() => {
        fetch("https://stoeger-recall.herokuapp.com/api/getAccessToken", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "post",
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setSerialData({
                    ...serialData,
                    accessToken: data.access_token,
                });
            })
            .catch((err) => console.log(err));
    }, []);

    const checkSerialNumber = () => {
        // fetch("http://localhost:8080/api/checkSerialNumber", {
        fetch("https://stoeger-recall.herokuapp.com/api/checkSerialNumber", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(serialData),
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                // console.log(data);
                //check if serial number is part of recall, and case imcomplete
                if (data[2] && !data[3] && !data[4]) {
                    // console.log("redirect");
                    props.history.push({
                        pathname: "/recallform",
                        state: { ...serialData },
                    });
                }
                if (!data[2]) {
                    setShowCaseStatus(true);
                    setCaseStatus("The serial number you have entered is not part of the recall.");
                } else if (data[3]) {
                    setShowCaseStatus(true);
                    setCaseStatus(`The repair has been completed for recall serial number ${serialData.serial}.`);
                } else if (data[4]) {
                    setShowCaseStatus(true);
                    setCaseStatus(
                        `A case has been started for serial number ${serialData.serial}. You will receive an email with shipping instructions.`
                    );
                }
            })
            .catch((err) => console.log(err));
    };
    return (
        <div
            style={{
                background: "url('https://cdn2.hubspot.net/hub/302700/file-352328646-jpg/images/site_bg.jpg') repeat",
            }}
        >
            <Header />
            <Paper className={classes.noticeBoard}>
                <div className={classes.intro}>
                    <p>RECALL NOTICE – BERETTA NEOS PISTOLS</p>
                </div>
                <section className={classes.section}>
                    <p
                        style={{
                            fontSize: "26px",
                            lineHeight: "31.5px",
                            color: "#ff6b11",
                            paddingTop: "40px",
                            marginTop: 0,
                        }}
                    >
                        HERE IS HOW IT WORKS
                    </p>
                    <div className={classes.div}>
                        <ol>
                            <li>
                                <p className={classes.li}>Enter your Neos serial number</p>
                            </li>
                            <li>
                                <p
                                    style={{
                                        fontWeight: "bold",
                                        textTransform: "uppercase",
                                        color: "#4a4a4a",
                                        lineHeight: "19.6px",
                                        margin: "10px 0 0",
                                    }}
                                >
                                    Submit your contact information
                                </p>
                                <span className={classes.liP}>
                                    If your serial number is part of the recall list, you will be prompted to enter
                                    additional information
                                </span>
                            </li>
                            <li>
                                <p
                                    style={{
                                        fontWeight: "bold",
                                        textTransform: "uppercase",
                                        color: "#4a4a4a",
                                        lineHeight: "19.6px",
                                        margin: "10px 0 0",
                                    }}
                                >
                                    Print Shipping label
                                </p>
                                <span className={classes.liP}>
                                    You will receive an email with further shipping instructions, including a pre-paid
                                    Canada Post label.
                                </span>
                            </li>
                            <li>
                                <p className={classes.li}>Ship your item to STOEGER CANADA</p>
                            </li>
                            <li>
                                <p className={classes.li}>STOEGER CANADA will repair your item</p>
                            </li>
                            <li>
                                <p className={classes.li}>STOEGER CANADA will ship your item back to you</p>
                            </li>
                        </ol>
                    </div>
                </section>
                <section className={classes.section2}>
                    <div className={classes.div}>
                        <p
                            style={{
                                fontSize: "26px",
                                lineHeight: "31.5px",
                                color: "#ff6b11",
                                paddingTop: "40px",
                                marginTop: 0,
                            }}
                        >
                            GET STARTED
                        </p>
                        <div>
                            <InputLabel id="serialnumber">Check Serial Number</InputLabel>
                            <TextField
                                variant="outlined"
                                fullWidth
                                inputProps={{
                                    style: {
                                        padding: 5,
                                    },
                                }}
                                style={{ marginTop: "5px" }}
                                value={serialData.serial}
                                onChange={handleSerialData}
                            />
                            <Button
                                variant="contained"
                                color="default"
                                onClick={checkSerialNumber}
                                className={classes.btn}
                            >
                                Submit
                            </Button>
                            {showCaseStatus && <div className={classes.case}>{caseStatus}</div>}
                        </div>
                        <div style={{ marginBottom: 40 }} />
                    </div>
                    <div className={classes.div}>
                        <p style={{ fontSize: "12px" }}>
                            <span style={{ color: "#999999" }}>IF YOU HAVE QUESTIONS PLEASE CONTACT US AT:</span>
                            <br />
                            <span style={{ color: "#999999" }}>
                                service@stoegercanada.ca | 1-800-263-1945 | 905-436-9077
                            </span>
                        </p>
                    </div>
                </section>
            </Paper>
            <Footer />
        </div>
    );
}
