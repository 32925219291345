import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    footer: {
        background:
            "url('https://cdn2.hubspot.net/hub/302700/file-350827958-jpg/images/header-container-bg.jpg') no-repeat left top #152f44",
        color: "white",
        width: "100%",
        textAlign: "center",
        height: 100,
        paddingTop: 20,
        fontSize: 12,
    },
}));

export default function Footer() {
    const classes = useStyles();
    return (
        <footer className={classes.footer}>
            <span>Copyright © 2021 Stoeger Canada</span>
        </footer>
    );
}
