import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import RecallNotice from "./component/RecallNotice";
import Recall from "./component/Recall";
import RecallForm from "./component/RecallForm";

export default function AppRouter() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={RecallNotice} />
                <Route path="/recall" exact component={Recall} />
                <Route path="/recallform" exact component={RecallForm} />
            </Switch>
        </BrowserRouter>
    );
}
