import React, { useState } from "react";
import {
    Button,
    FormControl,
    InputLabel,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Header from "./Header";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
    noticeBoard: {
        // margin: "40px auto 80px",
        margin: "0 auto",
        width: "900px",
        // minWidth: "",
        padding: "30px",
    },
    title: {
        margin: "50px auto 5px",
        // paddingLeft: "25px",
        width: "900px",
    },
    spacing: {
        margin: "10px 0",
        backgroundColor: "#f0f0f0",
    },
    button: {
        margin: "30px 0",
    },
    formControl: {
        minWidth: 120,
    },
    case: {
        margin: "20px auto 10px",
        width: "50%",
        border: "2px solid #F34F03",
        fontSize: "18px",
        padding: "20px",
        textAlign: "center",
    },
    loading: {
        margin: "30px 0",
    },
}));

export default function RecallForm(props) {
    const classes = useStyles();
    const [recallInfo, setRecallInfo] = useState({
        accessToken: props.history.location.state.accessToken,
        serialNumber: props.history.location.state.serial,
        first: "",
        last: "",
        email: "",
        phone: "",
        street: "",
        city: "",
        province: "",
        postal: "",
        pal: "",
        dealerAccount: "",
        dealerEmail: "",
    });
    const [caseCreated, setCaseCreate] = useState({});

    const [disableBtn, setDisableBtn] = useState(false);
    const [loading, setLoading] = useState(false);

    const [caseNumber, setCaseNumber] = useState("");

    const handleRecallInfo = (e) => {
        const { name, value } = e.target;

        setRecallInfo({
            ...recallInfo,
            [name]: value,
        });
    };

    const checkSerialNumber = (e) => {
        e.preventDefault();
        setDisableBtn(true);
        setLoading(true);

        // fetch("http://localhost:8080/api/createRecallCase", {
        fetch("https://stoeger-recall.herokuapp.com/api/createRecallCase", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(recallInfo),
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setCaseCreate(data);

                setLoading(false);
                setCaseNumber(data.caseNumber);
                // setShowResult(true);
            })
            .catch((err) => console.log(err));
    };

    // const afterSubmit = () => {
    //     if (!caseCreated.success) {
    //         return (
    //             <div className={classes.case}>
    //                 There is an error in the details. Please contact Stoeger Canada directly to start a case.
    //                 <br />
    //                 1-800-263-1945.
    //             </div>
    //         );
    //     } else {
    //         return (
    //             !!caseCreated.caseNumber && (
    //                 <div className={classes.case}>
    //                     A repair case has been successfully created.
    //                     <br />
    //                     Case Number: <u>{caseCreated.caseNumber}</u>
    //                     <br />
    //                     You will receive an email with further shipping instructions.
    //                 </div>
    //             )
    //         );
    //     }
    // };

    return (
        <div
            style={{
                background: "url('https://cdn2.hubspot.net/hub/302700/file-352328646-jpg/images/site_bg.jpg') repeat",
            }}
        >
            <Header />

            <Typography variant="h5" className={classes.title}>
                Create Recall Case
            </Typography>
            <Paper className={classes.noticeBoard}>
                <Typography variant="h5" style={{ margin: "10px 0" }}>
                    Serial Number: {recallInfo.serialNumber}
                </Typography>
                <form onSubmit={checkSerialNumber}>
                    <TextField
                        variant="outlined"
                        className={classes.spacing}
                        fullWidth
                        label="First Name"
                        required={true}
                        name="first"
                        value={recallInfo.first}
                        onChange={handleRecallInfo}
                    />
                    <TextField
                        variant="outlined"
                        className={classes.spacing}
                        fullWidth
                        label="Last Name"
                        required={true}
                        name="last"
                        value={recallInfo.last}
                        onChange={handleRecallInfo}
                    />
                    <TextField
                        variant="outlined"
                        className={classes.spacing}
                        fullWidth
                        label="Email"
                        required={true}
                        type="email"
                        name="email"
                        value={recallInfo.email}
                        onChange={handleRecallInfo}
                    />
                    <TextField
                        variant="outlined"
                        className={classes.spacing}
                        fullWidth
                        label="Phone Number"
                        name="phone"
                        value={recallInfo.phone}
                        onChange={handleRecallInfo}
                    />
                    <TextField
                        variant="outlined"
                        className={classes.spacing}
                        fullWidth
                        label="Street address"
                        required={true}
                        name="street"
                        value={recallInfo.street}
                        onChange={handleRecallInfo}
                    />
                    <TextField
                        variant="outlined"
                        className={classes.spacing}
                        fullWidth
                        label="City"
                        required={true}
                        name="city"
                        value={recallInfo.city}
                        onChange={handleRecallInfo}
                    />

                    <FormControl variant="outlined" className={classes.spacing} fullWidth required={true}>
                        <InputLabel id="province">Province</InputLabel>
                        <Select
                            labelId="province"
                            name="province"
                            value={recallInfo.province}
                            onChange={handleRecallInfo}
                        >
                            <MenuItem value="Alberta">Alberta</MenuItem>
                            <MenuItem value="British Columbia">British Columbia</MenuItem>
                            <MenuItem value="Manitoba">Manitoba</MenuItem>
                            <MenuItem value="New Brunswick">New Brunswick</MenuItem>
                            <MenuItem value="Newfoundland and Labrador">Newfoundland and Labrador</MenuItem>
                            <MenuItem value="Northwest Territories">Northwest Territories</MenuItem>
                            <MenuItem value="Nova Scotia">Nova Scotia</MenuItem>
                            <MenuItem value="Nunavut">Nunavut</MenuItem>
                            <MenuItem value="Ontario">Ontario</MenuItem>
                            <MenuItem value="Prince Edward Island">Prince Edward Island</MenuItem>
                            <MenuItem value="Quebec">Quebec</MenuItem>
                            <MenuItem value="Saskatchewan">Saskatchewan</MenuItem>
                            <MenuItem value="Yukon">Yukon</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        variant="outlined"
                        className={classes.spacing}
                        fullWidth
                        label="Postal Code"
                        required={true}
                        name="postal"
                        value={recallInfo.postal}
                        onChange={handleRecallInfo}
                    />
                    <TextField
                        variant="outlined"
                        className={classes.spacing}
                        fullWidth
                        required={recallInfo.dealerAccount === ""}
                        label="PAL/POL Number"
                        name="pal"
                        value={recallInfo.pal}
                        onChange={handleRecallInfo}
                    />
                    <Typography variant="h6" style={{ margin: "10px 0" }}>
                        Dealer
                    </Typography>

                    <TextField
                        variant="outlined"
                        className={classes.spacing}
                        fullWidth
                        label="Dealer Account Number"
                        name="dealerAccount"
                        value={recallInfo.dealerAccount}
                        onChange={handleRecallInfo}
                    />
                    <TextField
                        variant="outlined"
                        className={classes.spacing}
                        fullWidth
                        label="Dealer Email"
                        type="email"
                        name="dealerEmail"
                        value={recallInfo.dealerEmail}
                        onChange={handleRecallInfo}
                    />

                    {loading ? (
                        <CircularProgress className={classes.loading} />
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            type="submit"
                            disabled={disableBtn}
                        >
                            Submit
                        </Button>
                    )}
                </form>
                {/* <Button variant="contained" color="primary" className={classes.button} onClick={checkSerialNumber}>
                    Submit
                </Button> */}
                <div align="center">
                    {/* {Object.entries(caseCreated).length !== 0 && afterSubmit()} */}
                    {Object.entries(caseCreated).length !== 0 && !caseCreated.success && (
                        <div className={classes.case}>
                            There is an error in the details. Please contact Stoeger Canada directly to start a case.
                            <br />
                            1-800-263-1945.
                        </div>
                    )}
                    {!!caseNumber && (
                        <div className={classes.case}>
                            A repair case has been successfully created.
                            <br />
                            Case Number: <u>{caseCreated.caseNumber}</u>
                            <br />
                            You will receive an email with further shipping instructions.
                        </div>
                    )}
                    {Object.entries(caseCreated).length !== 0 && (
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            href="https://www.stoegercanada.ca/"
                        >
                            Return to Stoeger Canada
                        </Button>
                    )}
                </div>

                <div>
                    <div />
                    <p>
                        IF YOU HAVE QUESTIONS PLEASE CONTACT US AT:
                        <br />
                        service@stoegercanada.ca | 1-800-263-1945 | 905-436-9077
                    </p>
                </div>
            </Paper>
            <Footer />
        </div>
    );
}
